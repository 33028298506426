// ContactForm.js

import React, { useState } from 'react';
import styles from './ContactForm.module.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        company: '',
        country: 'India',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
       
        console.log(formData);
    };

    return (
        <>
            <h2 className={styles.heading}>Contact Us</h2>
            <form onSubmit={handleSubmit} className={styles.contactForm}>
                <div className={styles.row}>
                    <div className={styles.formGroup}>
                        <label htmlFor="name" className={styles.label}>Your Name<span style={{ color: 'red' }}>*</span></label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className={styles.formInput}
                            placeholder='Tarun rg '
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="email" className={styles.label}>Your Email<span style={{ color: 'red' }}>*</span></label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className={styles.formInput}
                            placeholder='tarun888@gmail.com'
                            required
                        />
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.formGroup}>
                        <label htmlFor="company" className={styles.label}>Company Name<span style={{ color: 'red' }}>*</span></label>
                        <input
                            type="text"
                            id="company"
                            name="company"
                            value={formData.company}
                            onChange={handleChange}
                            className={styles.formInput}
                            placeholder='KAPXR'
                            required
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label htmlFor="country" className={styles.label}>Country<span style={{ color: 'red' }}>*</span></label>
                        <input
                            type="text"
                            id="country"
                            name="country"
                            value={formData.country}
                            onChange={handleChange}
                            className={styles.formInput}
                            placeholder='india'
                            required
                        />
                    </div>
                </div>
                <div className={styles.formGroup}>
                    <label htmlFor="message" className={styles.label}>Your Message<span style={{ color: 'red' }}>*</span></label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        placeholder='Type your message .....'
                        className={styles.formTextarea}
                        required
                    />
                </div>
                <button type="submit" className={styles.submitButton}>
                    Send  <svg width="35" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_408_38)">
                            <path d="M6.26488 5.37863L28.0009 16L6.26488 26.6213C6.14081 26.669 6.00537 26.6787 5.87575 26.6493C5.74613 26.6198 5.62819 26.5526 5.53688 26.456C5.4429 26.3568 5.37821 26.2336 5.34997 26.0999C5.32173 25.9662 5.33104 25.8273 5.37688 25.6986L8.66755 16L5.37688 6.30129C5.33104 6.17259 5.32173 6.03371 5.34997 5.90004C5.37821 5.76637 5.4429 5.64312 5.53688 5.54396C5.62819 5.44736 5.74613 5.38008 5.87575 5.35064C6.00537 5.3212 6.14081 5.33094 6.26488 5.37863Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M8.66602 16H27.9993" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_408_38">
                                <rect width="32" height="32" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                </button>
            </form>
        </>
    );
};

export default ContactForm;
