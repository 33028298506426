import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './IndividualBlogPage.module.css';

// Import images directly
import image1 from '../../../Assets/Images/BlogPage/blog_card_1.png';
import image2 from '../../../Assets/Images/BlogPage/blog_card_2.png';
import image3 from '../../../Assets/Images/BlogPage/blog_card_3.png';
import image4 from '../../../Assets/Images/BlogPage/blog_card_4.png';
import image5 from '../../../Assets/Images/BlogPage/blog_card_5.png';
import image6 from '../../../Assets/Images/BlogPage/blog_card_6.png';

const blogData = [
    { id: 1, image: image1, title: "India's No: 1 Consultancy Award is Breached once again By Kapxr", description: "Exciting news awaits inside the blog!" },
    { id: 2, image: image2, title: "India's No: 1 Consultancy Award is Breached once again By Kapxr", description: "Exciting news awaits inside the blog!" },
    { id: 3, image: image3, title: "India's No: 1 Consultancy Award is Breached once again By Kapxr", description: "Exciting news awaits inside the blog!" },
    { id: 4, image: image4, title: "India's No: 1 Consultancy Award is Breached once again By Kapxr", description: "Exciting news awaits inside the blog!" },
    { id: 5, image: image5, title: "India's No: 1 Consultancy Award is Breached once again By Kapxr", description: "Exciting news awaits inside the blog!" },
    { id: 6, image: image6, title: "India's No: 1 Consultancy Award is Breached once again By Kapxr", description: "Exciting news awaits inside the blog!" },
];

const IndividualBlogPage = () => {
    const { id } = useParams();
    const blog = blogData.find(blog => blog.id === parseInt(id));

    if (!blog) return <div>Blog not found</div>;

    return (
        <div className={styles.blogContainer}>
            <img src={blog.image} alt="Blog" className={styles.blogImage} />
            <div className={styles.blogCaption}>
                <p className={styles.captionText}>{blog.title}</p>
                <p className={styles.captionText}>{blog.description}</p>
            </div>
        </div>
    );
};

export default IndividualBlogPage;
