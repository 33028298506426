// MainFooter.js

import React from 'react';
import styles from './MainFooter.module.css';
import SiteFooter from './SiteFooter';

const MainFooter = () => {
    return (
    <> 
       <SiteFooter/>
        <footer className={styles.footer}>
            <div className={styles.leftContent}>
                <p>© 2024 Kapxr all rights reserved</p>
            </div>
            <div className={styles.rightContent}>
                <p>Privacy Policy</p>
                <p>|</p>
                <p>Legal Disclaimer</p>
                <p>|</p>
                <p>Terms & Conditions</p>
            </div>
        </footer>
         
        </>
    );
};

export default MainFooter;
