import React from 'react';
import CountUp from 'react-countup';
import styles from './OurNumbers.module.css';

const OurNumbers = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.headingContainer}>
                    <h2 className={styles.heading}>OUR NUMBERS</h2>
                </div>
                <div className={styles.cardContainer}>
                    <div className={styles.card}>
                        <h3 className={styles.number}>
                            <CountUp end={501000} duration={5.75} separator="," suffix="+" />
                        </h3>
                        <p className={styles.description}>Our Employees</p>
                        <p className={styles.details}>Workflow globally distributed, highly localized</p>
                    </div>
                    <div className={styles.card}>
                        <h3 className={styles.number}>
                            <CountUp end={143} duration={5.75} />
                        </h3>
                        <p className={styles.description}>Nationalist</p>
                        <p className={styles.details}>Workflow globally distributed, highly localized</p>
                    </div>
                    <div className={styles.card}>
                        <h3 className={styles.number}>
                            <CountUp end={45.3} duration={5.75} decimals={1} suffix="%" />
                        </h3>
                        <p className={styles.description}>Diversity</p>
                        <p className={styles.details}>Workflow globally distributed, highly localized</p>
                    </div>
                    <div className={styles.card}>
                        <h3 className={styles.number}>
                            <CountUp end={300000} duration={5.75} separator="," />
                        </h3>
                        <p className={styles.description}>Development</p>
                        <p className={styles.details}>Workflow globally distributed, highly localized</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurNumbers;
