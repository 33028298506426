import React from 'react';
import Navbar from '../Components/Navbar/Navbar';
import VideoCarousel from '../Components/Carousel/Video/VideoCarousel';
import BusinessComponent from '../Components/Business/BusinessComponent';
import BlogComponent from '../Components/Blogs/BlogComponent';
import MainFooter from '../Components/Footer/MainFooter';
import Services from '../Components/Services/Services';
import ContactUsButton from '../Components/ContactUsButton/ContactUsButton'
import windmill from '../Pages/windmill.jpg';

function HomePage(props) {


    return (
      
<>
     <Navbar/>
     <ContactUsButton/>
     {/* <img src={windmill} style={{ width: '100%', height : '50%', padding: 20 }} alt="Windmill"></img> */}
     <VideoCarousel/>
     {/* <BusinessComponent/>  */}
     <Services/>
     {/* <BlogComponent/> */}
     <MainFooter/>
     </>
        
    );
}

export default HomePage;

// The play() request was interrupted because video-only background media was paused to save power. https://goo.gl/LdLk22