import React from 'react'
import Navbar from '../Components/Navbar/Navbar'
import MainFooter from '../Components/Footer/MainFooter'
import ImageAboutus from '../Components/AboutUs/Image_AboutUs/ImageAboutus'
import InsideKAPXR from '../Components/AboutUs/InsideKAPXR/InsideKAPXR'
import OurPeople from '../Components/AboutUs/OurPeople/OurPeople'
import OurNumbers from '../Components/AboutUs/OurNumbers/OurNumbers'
import GetToKnowAboutUs from '../Components/AboutUs/AboutUsCards/GetToKnowAboutUs'

function AboutUs() {
  return (
    <>
    <Navbar/>
    <ImageAboutus/>
    <InsideKAPXR/>
    <OurPeople/>
    <OurNumbers/>
    <GetToKnowAboutUs/>
    <MainFooter/>

    </>
  )
}

export default AboutUs