import React from 'react';
import styles from './InsideKAPXR.module.css';
import image1 from '../../../Assets/Images/AboutUs/AboutUs_HomePage_2.png'
const InsideKAPXR = () => {
    return (
        <div className={styles.container}>
            <h2 className={styles.heading}>INSIDES OF KAPXR</h2>
            <div className={styles.navItems}>
                <div className={styles.navigationItem}>Our commitment</div>
                <div className={styles.navigationItem}>Our road-map</div>
                <div className={styles.navigationItem}>Our context</div>
                <div className={styles.navigationItem}>Our beliefs</div>
                <div className={styles.navigationItem}>Our stories</div>
            </div>
            <div className={styles.contentContainer}>
                <div className={styles.textContainer}>
                    <h2 className={styles.heading1}>Crew Bonding is Beyond </h2>
                    <p className={styles.description}>
                        The Bonding of the KAPXR and the employees are still in the
                        way on the needed data of the consultancy , connect and all
                        the service in action.
                    </p>
                </div>
                <img src={image1} alt="TCS Logo" className={styles.tcsLogo} />
            </div>
            <hr className={styles.horizontalLine1} />
        </div>
    );
};

export default InsideKAPXR;
