// ContactUsIntro.js

import React from 'react';
import styles from './ContactUsIntro.module.css';

const ContactUsIntro = () => {
    return (
        <div className={styles.container}>
            <div className={styles.mainheading}>
                <h2 className={styles.heading}>What’s on your mind ?</h2>
                <p className={styles.subheading}>We’re here to help! Tell us what you’re looking for and we’ll get connected to the right people.</p>
            </div>

            <div className={styles.buttonRow}>
                <button className={styles.button}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_408_143)">
                            <path d="M12.2431 8.156C10.5178 7.80951 8.72639 8.04157 7.1463 8.81624C5.56621 9.59091 4.28563 10.865 3.5029 12.4411C2.72017 14.0172 2.47896 15.8074 2.81664 17.5345C3.15431 19.2616 4.05203 20.8291 5.37074 21.9943C6.68946 23.1596 8.35559 23.8575 10.1111 23.98C11.8666 24.1025 13.6135 23.6427 15.0813 22.6719C16.549 21.7011 17.6558 20.2735 18.23 18.61C18.8043 16.9466 18.8141 15.1402 18.2578 13.4707" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M19.7508 23.844C21.478 24.1923 23.2718 23.9606 24.8538 23.185C26.4358 22.4094 27.7175 21.1332 28.4999 19.5545C29.2823 17.9759 29.5217 16.1831 29.181 14.4545C28.8402 12.7258 27.9382 11.158 26.6152 9.99449C25.2921 8.83093 23.622 8.13669 21.864 8.01954C20.106 7.90239 18.3584 8.36888 16.8927 9.34659C15.427 10.3243 14.325 11.7585 13.7578 13.4267C13.1907 15.0948 13.1901 16.9035 13.7561 18.572" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_408_143">
                                <rect width="32" height="32" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    KAPXR Connect
                </button>
                <button className={styles.button}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_409_147)">
                            <path d="M5.33398 16C5.33398 16.5253 5.43745 17.0454 5.63847 17.5307C5.83949 18.016 6.13412 18.457 6.50556 18.8284C6.87699 19.1999 7.31795 19.4945 7.80325 19.6955C8.28855 19.8965 8.8087 20 9.33398 20C9.85927 20 10.3794 19.8965 10.8647 19.6955C11.35 19.4945 11.791 19.1999 12.1624 18.8284C12.5338 18.457 12.8285 18.016 13.0295 17.5307C13.2305 17.0454 13.334 16.5253 13.334 16C13.334 15.4747 13.2305 14.9546 13.0295 14.4693C12.8285 13.984 12.5338 13.543 12.1624 13.1716C11.791 12.8001 11.35 12.5055 10.8647 12.3045C10.3794 12.1035 9.85927 12 9.33398 12C8.8087 12 8.28855 12.1035 7.80325 12.3045C7.31795 12.5055 6.87699 12.8001 6.50556 13.1716C6.13412 13.543 5.83949 13.984 5.63847 14.4693C5.43745 14.9546 5.33398 15.4747 5.33398 16Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.666 9.33334C18.666 10.3942 19.0874 11.4116 19.8376 12.1618C20.5877 12.9119 21.6051 13.3333 22.666 13.3333C23.7269 13.3333 24.7443 12.9119 25.4944 12.1618C26.2446 11.4116 26.666 10.3942 26.666 9.33334C26.666 8.27248 26.2446 7.25506 25.4944 6.50492C24.7443 5.75477 23.7269 5.33334 22.666 5.33334C21.6051 5.33334 20.5877 5.75477 19.8376 6.50492C19.0874 7.25506 18.666 8.27248 18.666 9.33334Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M18.666 22.6667C18.666 23.7275 19.0874 24.7449 19.8376 25.4951C20.5877 26.2452 21.6051 26.6667 22.666 26.6667C23.7269 26.6667 24.7443 26.2452 25.4944 25.4951C26.2446 24.7449 26.666 23.7275 26.666 22.6667C26.666 21.6058 26.2446 20.5884 25.4944 19.8382C24.7443 19.0881 23.7269 18.6667 22.666 18.6667C21.6051 18.6667 20.5877 19.0881 19.8376 19.8382C19.0874 20.5884 18.666 21.6058 18.666 22.6667Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M13.334 16H17.334L20.0007 12.6667" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M20.0007 19.3333L17.334 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_409_147">
                                <rect width="32" height="32" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    KAPXR XR 
                </button>
                <button className={styles.button}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_409_154)">
                            <path d="M4 12C4 11.2928 4.28095 10.6145 4.78105 10.1144C5.28115 9.61429 5.95942 9.33334 6.66667 9.33334H25.3333C26.0406 9.33334 26.7189 9.61429 27.219 10.1144C27.719 10.6145 28 11.2928 28 12V24C28 24.7073 27.719 25.3855 27.219 25.8856C26.7189 26.3857 26.0406 26.6667 25.3333 26.6667H6.66667C5.95942 26.6667 5.28115 26.3857 4.78105 25.8856C4.28095 25.3855 4 24.7073 4 24V12Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M21.3327 4L15.9993 9.33333L10.666 4" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </g>
                        <defs>
                            <clipPath id="clip0_409_154">
                                <rect width="32" height="32" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                    Media Contact 
                </button>
            </div>
            <p className={styles.bottomText}>Looking for something else?</p>
        </div>
    );
};

export default ContactUsIntro;
