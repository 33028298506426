import React, { useState , useEffect } from 'react';
import styles from './VideoCarousel.module.css';
import videoSource from '../../../Assets/video_1.mp4';

const VideoCarousel = () => {
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        const video = document.getElementById('video');
        video.play();
    }, []);

    const togglePlay = () => {
        setIsPlaying(!isPlaying);
        const video = document.getElementById('video');
        if (isPlaying) {
            video.pause();
        } else {
            video.play();
        }
    };

    return (
        <div className={styles.videoContainer}>
             <video
                id="video"
                className={styles.video}
                controls={false}
                autoPlay
                muted
                loop
                preload="metadata"
                playsInline
            >
                <source src={videoSource} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
         
        </div>
    );
};

export default VideoCarousel;
