import React from 'react';
import Navbar from '../Components/Navbar/Navbar';
import MainFooter from '../Components/Footer/MainFooter';
 import ImageBlog from '../Components/BlogPageComponents/ImageBlog/ImageBlog';
import BlogCards from '../Components/BlogPageComponents/BlogCards/BlogCards';
import AchievementCards from '../Components/BlogPageComponents/AchievementCards/AchievementCards';
import BlogPeople from '../Components/BlogPageComponents/BlogPeople/BlogPeople';
import BlogNews from '../Components/BlogPageComponents/BlogNews/BlogNews';

function BlogPage(props) {
    return (
        <div>
            <Navbar/>
            <ImageBlog/>
            <BlogCards/>
            <AchievementCards/>
            <BlogPeople/>
            <BlogNews/>
            <MainFooter/>
        </div>
    );
}

export default BlogPage;