// GetToKnowAboutUs.js

import React, { useState } from 'react';
import styles from './GetToKnowAboutUs.module.css';
import image1 from '../../../Assets/Images/AboutUs/AboutUs_Card.png'; // Import the image for card 1

const GetToKnowAboutUs = () => {
    const [currentCardIndex, setCurrentCardIndex] = useState(0);

    const cards = [
        {
            image: image1,
            heading: 'Card 1',
            description: 'Description for card 1'
        },
        {
            image: image1,
            heading: 'Card 2',
            description: 'Description for card 2'
        },
        {
            image: image1,
            heading: 'Card 3',
            description: 'Description for card 3'
        },
        {
            image: image1,
            heading: 'Card 4',
            description: 'Description for card 4'
        },
        {
            image: image1,
            heading: 'Card 5',
            description: 'Description for card 5'
        }
    ];

    const handleNextCard = () => {
        setCurrentCardIndex((prevIndex) => (prevIndex + 1) % cards.length);
    };

    return (
        <div className={styles.container}>
            <h2 className={styles.heading}>GET MORE TO KNOW ABOUT US</h2>
            <div className={styles.cardContainer}>
                {cards.slice(currentCardIndex, currentCardIndex + 4).map((card, index) => (
                    <div key={index} className={`${styles.card} ${styles.active}`}>
                        <img src={card.image} alt={`Card ${index + 1}`} className={styles.cardImage} />
                        <div className={styles.cardContent}>
                            <h3 className={styles.cardHeading}>{card.heading}</h3>
                            <p className={styles.cardDescription}>{card.description}</p>
                            <div className={styles.newsroom}>
                                <span className={styles.newsroomText}>Newsroom</span>
                                <button className={styles.readMoreButton}>Read More</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <button className={styles.nextButton} onClick={handleNextCard}><svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_426_32)">
                    <path d="M6.66602 16H25.3327" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M20 21.3333L25.3333 16" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M20 10.6667L25.3333 16.0001" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_426_32">
                        <rect width="32" height="32" fill="white" />
                    </clipPath>
                </defs>
            </svg>
            </button>
        </div>
    );
};

export default GetToKnowAboutUs;
