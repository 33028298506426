// LatestBlogs.js

import React from 'react';
import styles from './AchievementCards.module.css';
import image1 from '../../../Assets/Images/BlogPage/Achievement_Card_1.png'
import image2 from '../../../Assets/Images/BlogPage/Achievement_Card_2.png'
import image3 from '../../../Assets/Images/BlogPage/Achievement_Card_3.png'
import image4 from '../../../Assets/Images/BlogPage/Achievement_Card_4.png'
const AchievementCards = () => {
    return (
        <div className={styles.latestBlogs}>
            <h2 className={styles.heading}>Achievements of Our Team</h2>
            <div className={styles.cardsContainer}>

                <div className={styles.card}>
                    <img src={image1} alt="Blog Image" className={styles.blogImage} />
                    <div className={styles.caption}>
                        IT Contest winners - 2022
                    </div>

                    <p className={styles.description}>
                        Global ranking 1st among
                        10,000 participation
                    </p>

                </div>
                <div className={styles.card}>
                    <img src={image2} alt="Blog Image" className={styles.blogImage} />
                    <div className={styles.caption}>
                        IT Contest winners - 2022
                    </div>

                    <p className={styles.description}>
                        Global ranking 1st among
                        10,000 participation
                    </p>

                </div>
                <div className={styles.card}>
                    <img src={image3} alt="Blog Image" className={styles.blogImage} />
                    <div className={styles.caption}>
                        IT Contest winners - 2022
                    </div>

                    <p className={styles.description}>
                        Global ranking 1st among
                        10,000 participation
                    </p>

                </div>
                <div className={styles.card}>
                    <img src={image4} alt="Blog Image" className={styles.blogImage} />
                    <div className={styles.caption}>
                        IT Contest winners - 2022
                    </div>

                    <p className={styles.description}>
                        Global ranking 1st among
                        10,000 participation
                    </p>

                </div>
                <button className={styles.exploreButton}>Read More</button>

            </div>
            <hr className={styles.horizontalLine} />
        </div>
    );
};

export default AchievementCards;
