// LatestBlogs.js

import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './BlogCards.module.css';
import image1 from '../../../Assets/Images/BlogPage/blog_card_1.png';
import image2 from '../../../Assets/Images/BlogPage/blog_card_2.png';
import image3 from '../../../Assets/Images/BlogPage/blog_card_3.png';
import image4 from '../../../Assets/Images/BlogPage/blog_card_4.png';
import image5 from '../../../Assets/Images/BlogPage/blog_card_5.png';
import image6 from '../../../Assets/Images/BlogPage/blog_card_6.png';

const blogData = [
    { id: 1, image: image1, title: "India's No: 1 Consultancy Award is Breached once again By Kapxr", date: "April 10, 2024", category: "Sustainability & the product management", description: "I began walking, therefore, in a big curve, seeking some point of vantage and continually looking a…" },
    { id: 2, image: image2, title: "India's No: 1 Consultancy Award is Breached once again By Kapxr", date: "April 10, 2024", category: "Sustainability & the product management", description: "I began walking, therefore, in a big curve, seeking some point of vantage and continually looking a…" },
    { id: 3, image: image3, title: "India's No: 1 Consultancy Award is Breached once again By Kapxr", date: "April 10, 2024", category: "Sustainability & the product management", description: "I began walking, therefore, in a big curve, seeking some point of vantage and continually looking a…" },
    { id: 4, image: image4, title: "India's No: 1 Consultancy Award is Breached once again By Kapxr", date: "April 10, 2024", category: "Sustainability & the product management", description: "I began walking, therefore, in a big curve, seeking some point of vantage and continually looking a…" },
    { id: 5, image: image5, title: "India's No: 1 Consultancy Award is Breached once again By Kapxr", date: "April 10, 2024", category: "Sustainability & the product management", description: "I began walking, therefore, in a big curve, seeking some point of vantage and continually looking a…" },
    { id: 6, image: image6, title: "India's No: 1 Consultancy Award is Breached once again By Kapxr", date: "April 10, 2024", category: "Sustainability & the product management", description: "I began walking, therefore, in a big curve, seeking some point of vantage and continually looking a…" },
];

const BlogCards = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.latestBlogs}>
            <h2 className={styles.heading}>Latest Blogs</h2>
            <div className={styles.cardsContainer}>
                {blogData.map(blog => (
                    <div key={blog.id} className={styles.card}>
                        <img src={blog.image} alt="Blog" className={styles.blogImage} />
                        <div className={styles.caption}>{blog.title}</div>
                        <div className={styles.details}>
                            <span className={styles.date}>{blog.date}</span>
                            <span className={styles.separator}>|</span>
                            <span className={styles.category}>{blog.category}</span>
                        </div>
                        <p className={styles.description}>{blog.description}</p>
                        <div className={styles.readMore} onClick={() => navigate(`/blogs/individualblog/${blog.id}`)}>
                            <span className={styles.readMoreBtn}>READ MORE</span>
                            <div className={styles.arrow}>
                                <svg width="55" height="28" viewBox="0 0 55 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_339_30)">
                                        <path d="M11.459 14H43.5423" stroke="#1755F4" strokeWidth="0.916667" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M34.375 18.6667L43.5417 14" stroke="#1755F4" strokeWidth="0.916667" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M34.375 9.33337L43.5417 14" stroke="#1755F4" strokeWidth="0.916667" strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_339_30">
                                            <rect width="55" height="28" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <hr className={styles.horizontalLine} />
        </div>
    );
};

export default BlogCards;
