// BlogPage.js

import React from 'react';
import styles from './ImageBlog.module.css';
import blogImage from '../../../Assets/Images/BlogPage/blog_image.png';

const ImageBlog = () => {
    return (
        <div className={styles.blogContainer}>
            <img src={blogImage} alt="Blog" className={styles.blogImage} />
            <div className={styles.blogCaption}>
                <h2 className={styles.captionHeading}>Blog</h2>
                <p className={styles.captionText}>
                    Proud to reveal our history and success story of KAPXR.

                </p>
                <p className={styles.captionText}>
                    Exciting news awaits inside the blog!
                </p>
            </div>
        </div>
    );
};

export default ImageBlog;
