// ContactUsImage.js

import React from 'react';
import styles from './ContactUsImage.module.css';
import globalImage from '../../../Assets/Images/ContactUs/image.png'; // Import the image for global presence

const ContactUsImage = () => {
    return (
        <div className={styles.container}>
            <div className={styles.headings}>
                <h3 className={styles.heading}>Analyst Relations</h3>
                <h3 className={styles.heading}>Customer Complaints</h3>
                <h3 className={styles.heading}>Website Look</h3>
                <h3 className={styles.heading}>Customer Help ?</h3>
            </div>
            <img src={globalImage} alt="Global Presence" className={styles.image} />
            <div className={styles.overlayText}>
                <p className={styles.smallHeading}>Our Global Presence</p>
                <p className={styles.text}>Five continents. 153 nationalities. 603,000+ associates</p>
                <p className={styles.subtext}>North America Latin America Europe UK & Ireland India Asia Pacific Australia &</p>
            </div>
        </div>
    );
};

export default ContactUsImage;
