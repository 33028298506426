// LatestBlogs.js

import React from 'react';
import styles from './BlogNews.module.css';
import image1 from '../../../Assets/Images/BlogPage/blog_news_1.png'
import image2 from '../../../Assets/Images/BlogPage/blog_news_2.png'
import image3 from '../../../Assets/Images/BlogPage/blog_news_3.png'
import image4 from '../../../Assets/Images/BlogPage/blog_news_4.png'
const BlogNews = () => {
    return (
        <div className={styles.latestBlogs}>
            <h2 className={styles.heading}>KAPXR on News Stories </h2>
            <div className={styles.cardsContainer}>

                <div className={styles.card}>
                    <img src={image1} alt="Blog Image" className={styles.blogImage} />
                    <div className={styles.caption}>
                        India's No : 1 Sustainable Employees
                        in KAPXR
                    </div>

                    <p className={styles.description}>
                        <span>Date : 29.04.2023 </span>
                        <span>Article : Indian Express  </span>
                        <span>Event : Conference of G20 </span>
                    </p>

                </div>
                <div className={styles.card}>
                    <img src={image2} alt="Blog Image" className={styles.blogImage} />
                    <div className={styles.caption}>
                        India’s No : 1 Sustainable Employees
                        in KAPXR
                    </div>

                    <p className={styles.description}>
                        <span>Date : 29.04.2023 </span>
                        <span>Article : Indian Express  </span>
                        <span>Event : Conference of G20 </span>
                    </p>

                </div>
                <div className={styles.card}>
                    <img src={image3} alt="Blog Image" className={styles.blogImage} />
                    <div className={styles.caption}>
                        India’s No : 1 Sustainable Employees
                        in KAPXR
                    </div>

                    <p className={styles.description}>
                        <span>Date : 29.04.2023 </span>
                        <span>Article : Indian Express  </span>
                        <span>Event : Conference of G20 </span>
                    </p>

                </div>
                <div className={styles.card}>
                    <img src={image4} alt="Blog Image" className={styles.blogImage} />
                    <div className={styles.caption}>
                        India’s No : 1 Sustainable Employees
                        in KAPXR
                    </div>

                    <p className={styles.description}>
                        <span>Date : 29.04.2023 </span>
                        <span>Article : Indian Express  </span>
                        <span>Event : Conference of G20 </span>
                    </p>

                </div>
                <button className={styles.exploreButton}>Read More</button>

            </div>
            <hr className={styles.horizontalLine} />
        </div>
    );
};

export default BlogNews;
