import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './BlogAchievement.module.css';
import photo from '../../../Assets/Images/BlogPage/blog_achievement_1.png';
import icon1 from '../../../Assets/Images/BlogPage/Social_Media_icon_1.png';
import icon2 from '../../../Assets/Images/BlogPage/Social_Media_icon_2.png';
import icon3 from '../../../Assets/Images/BlogPage/Social_Media_icon_3.png';
import icon4 from '../../../Assets/Images/BlogPage/Social_Media_icon_4.png';

const blogAchievementsData = [
    {
        id: 1,
        title: "India’s No. 1 Consultancy Award By Kapxr",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vehicula felis ac tortor placerat, a varius sapien consectetur. Integer laoreet mi eget purus viverra, ac varius sapien consectetur. Donec venenatis leo ut lectus pulvinar, sit amet tempor mauris aliquam. Phasellus et metus nulla. Suspendisse potenti. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Etiam at vestibulum dui. Vestibulum euismod, leo vel aliquam tincidunt, dui sem rhoncus dolor, non condimentum nunc libero non purus.",
        quote: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam vehicula felis ac tortor placerat, a varius sapien consectetur. Integer laoreet mi eget purus viverra, ac varius sapien consectetur. Donec venenatis leo ut lectus pulvinar, sit amet tempor mauris aliquam."
    },
    {
        id: 2,
        title: "Leading Innovation in Consultancy Services",
        description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium. Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.",
        quote: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo."
    },
    {
        id: 3,
        title: "Excellence in Client Satisfaction",
        description: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga.",
        quote: "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur?"
    },
    {
        id: 4,
        title: "Pioneering Bespoke Strategies",
        description: "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus.",
        quote: "Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?"
    },
    {
        id: 5,
        title: "Driving Growth and Success",
        description: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae.",
        quote: "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident."
    },
    {
        id: 6,
        title: "Redefining Excellence",
        description: "Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.",
        quote: "Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus."
    },
];

const BlogAchievement = () => {
    const { id } = useParams();
    const achievement = blogAchievementsData.find(item => item.id === parseInt(id));

    if (!achievement) return <div>Achievement not found</div>;

    return (
        <div className={styles.container}>
            <h2 className={styles.heading}>{achievement.title}</h2>
            <div className={styles.description}>
                <p>{achievement.description}</p>
                <div className={styles.cardContainer}>
                    <p className={styles.description} style={{ width: '80%' }}>
                        "{achievement.quote}"
                    </p>
                    <div className={styles.card}>
                        <img src={photo} alt="Avatar" className={styles.avatar} />
                        <h3 className={styles.cardTitle}>Purushothaman Purswani</h3>
                        <p className={styles.cardSubtitle}>Chief Senior Technology Officer, KAPXR</p>
                        <p className={styles.viewDetails}>View Details</p>
                        <div className={styles.icons}>
                            <img src={icon1} alt="Icon" className={styles.icon} />
                            <img src={icon2} alt="Icon" className={styles.icon} />
                            <img src={icon3} alt="Icon" className={styles.icon} />
                            <img src={icon4} alt="Icon" className={styles.icon} />
                        </div>
                        <p className={styles.cardDescription}>
                            "I was particular on the tech stack on the view of global technology market on the scheme of the shareholder and to have a small recent meetings make this Award to win here as a blog"
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogAchievement;
