// Navbar.js

import React, { useState } from 'react';
import styles from './Navbar.module.css';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };


    return (
        <header className={styles.header}>
            <div className={styles.logo}>
            <a href='/'>
                <svg width="120" height="25" viewBox="0 0 243 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.208 0.719999V26.208L31.432 0.719999H45.184L27.76 23.472L45.328 54H31.864L20.128 33.408L12.208 43.776V54H0.544001V0.719999H12.208ZM58.8629 54H46.6949L65.7029 0.719999H77.0789L96.0149 54H83.6309L79.8869 42.768H62.6789L58.8629 54ZM69.8789 21.6L66.0629 32.976H76.5749L72.7589 21.6C72.1829 19.8 71.5349 17.712 71.3189 16.416C71.1029 17.64 70.5269 19.656 69.8789 21.6ZM123.816 36.288H113.88V54H102.216V0.719999H123.816C134.688 0.719999 141.96 7.848 141.96 18.504C141.96 29.16 134.688 36.288 123.816 36.288ZM121.224 11.088H113.88V25.92H121.224C126.84 25.92 129.648 23.472 129.648 18.504C129.648 13.536 126.84 11.088 121.224 11.088ZM162.861 27.36L145.725 0.719999H159.621L164.085 7.704C166.029 10.728 167.901 13.968 170.277 18.36L180.933 0.719999H194.541L177.693 27.36L194.541 54H180.717L177.189 48.456C174.525 44.208 172.797 41.04 170.205 36.288L158.757 54H145.077L162.861 27.36ZM213.021 54H201.357V0.719999H221.949C234.909 0.719999 242.253 6.984 242.253 18C242.253 24.912 239.301 29.808 233.397 32.832L242.757 54H230.013L222.021 35.352H213.021V54ZM213.021 11.088V25.128H221.877C226.989 25.128 229.941 22.536 229.941 18C229.941 13.536 227.133 11.088 221.949 11.088H213.021Z" fill="#1755F4"/>
                </svg>
                </a>
            </div>
            <nav className={`${styles.nav} ${isOpen ? styles.open : ''}`}>
                {/* <div className={styles.hamburger} onClick={toggleMenu}>
                    <div className={styles.line}></div>
                    <div className={styles.line}></div>
                    <div className={styles.line}></div>
                </div>
                <ul>
                    <li className={styles.dropdown} onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
                        <a href="/">Services</a>
                        {isDropdownOpen && (
                            <ul className={styles.dropdownMenu}>
                                <li><a href="/">Artificial Intelligence</a></li>
                                <li><a href="/">Cloud</a></li>
                                <li><a href="/">Consulting</a></li>
                                <li><a href="/">Data and Analytics</a></li>
                                <li><a href="/">Enterprise Solutions</a></li>
                                <li><a href="/">IoT and Digital Engineering</a></li>
                                <li><a href="/">Cybersecurity</a></li>
                            </ul>
                        )}
                    </li> 
                     <li><a href="/aboutus">About us</a></li> 
                     <li><a href="/blogs">Blogs</a></li> 
                     <li><a href="/contactus">Contact us</a></li>
                </ul> */}
            </nav>
        </header>
    );
};

export default Navbar;
