import React from 'react';
import styles from './Services.module.css';

const Services = () => {
    const services = [
        {
            title: "Artificial Intelligence",
            description: "Transfrom data into actionable insights and automate decision-making processes for efficiency.",
        },
        {
            title: "Process Automation",
            description: "Streamline tasks by using technology to perform repetitive actions, enhancing efficiency.",
 
        },
        {
            title: "Sustainability",
            description: "Calculate your carbon footprint, ESG Reporting, optimize resource use, reduce environmental impact.",
 
        },
        {
            title: "Cyber Security",
            description: "Protect systems, networks, and data from digital attacks, ensuring business continuity and safeguarding sensitive information.",
 
        },
        {
            title: "Software Development",
            description: "Custom solutions through design, coding, testing, and maintenance for optimal performance.",

        },
        {
            title: "Enterprise Platforms",
            description: "Streamline operations, boost productivity, and integrate processes, enabling efficient and scalable business management.",

        },
        {
            title: "Digital Workspace",
            description: " Streamline employee collaboration, enhance productivity, and support remote work with cloud-based tools, improving overall efficiency and flexibility in modern work environments",

        },
        {
            title: "Business Process Services",
            description: " Reduce operational costs, improve efficiency through specialized services, and maintain a strategic focus on primary objectives.",

        }
    ];

    return (
        <section className={styles.services}>
            <h1 className={styles.heading}>Services</h1>
            {/* <p className={styles.subtitle}>
                Your Digital Transformation beings here 
                <button className={styles.viewProductButton}>View the product</button>
            </p> */}
            <div className={styles.cardsContainer}>
                {services.map((service, index) => (
                    <div className={styles.card} key={index}>
                        <h3 className={styles.cardTitle}>{service.title}</h3>
                        <p className={styles.cardDescription}>{service.description}</p>
                        <a href="#" className={styles.cardLink}>{service.linkText}</a>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Services;
