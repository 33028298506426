// SiteFooter.js

import React from 'react';
import styles from './SiteFooter.module.css';

const SiteFooter = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerSection} style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'50px'}}>
            { <div className={styles.footerIcons} style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'10px'}}>
                    
                    {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <g clip-path="url(#clip0_293_31)">
                           <path d="M19.5007 3.25C20.212 3.25 20.9163 3.39011 21.5735 3.66232C22.2307 3.93453 22.8278 4.33352 23.3308 4.8365C23.8338 5.33949 24.2328 5.93662 24.505 6.5938C24.7772 7.25098 24.9173 7.95534 24.9173 8.66667V17.3333C24.9173 18.0447 24.7772 18.749 24.505 19.4062C24.2328 20.0634 23.8338 20.6605 23.3308 21.1635C22.8278 21.6665 22.2307 22.0655 21.5735 22.3377C20.9163 22.6099 20.212 22.75 19.5007 22.75H6.50065C5.78932 22.75 5.08496 22.6099 4.42778 22.3377C3.7706 22.0655 3.17347 21.6665 2.67049 21.1635C1.65467 20.1477 1.08398 18.7699 1.08398 17.3333V8.66667C1.08398 7.23008 1.65467 5.85233 2.67049 4.8365C3.68631 3.82068 5.06406 3.25 6.50065 3.25H19.5007ZM9.75065 9.75V16.25C9.7508 16.4416 9.80176 16.6297 9.89832 16.7952C9.99488 16.9606 10.1336 17.0975 10.3003 17.1919C10.4671 17.2863 10.6558 17.3348 10.8474 17.3324C11.039 17.33 11.2265 17.2769 11.3908 17.1784L16.8075 13.9284C16.9677 13.8321 17.1002 13.696 17.1922 13.5333C17.2842 13.3706 17.3326 13.1869 17.3326 13C17.3326 12.8131 17.2842 12.6294 17.1922 12.4667C17.1002 12.304 16.9677 12.1679 16.8075 12.0716L11.3908 8.82158C11.2265 8.72311 11.039 8.66998 10.8474 8.66761C10.6558 8.66523 10.4671 8.71371 10.3003 8.80808C10.1336 8.90246 9.99488 9.03936 9.89832 9.20483C9.80176 9.3703 9.7508 9.55842 9.75065 9.75Z" fill="#1755F4" />
                       </g>
                       <defs>
                           <clipPath id="clip0_293_31">
                               <rect width="26" height="26" fill="white" />
                           </clipPath>
                       </defs>
                   </svg> */}
                   <a href='https://www.instagram.com/kapxr_/' target="_blank"> 
                   <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <g clip-path="url(#clip0_293_34)">
                           <path d="M4.33398 8.66683C4.33398 7.51756 4.79053 6.41536 5.60319 5.6027C6.41585 4.79004 7.51805 4.3335 8.66732 4.3335H17.334C18.4833 4.3335 19.5855 4.79004 20.3981 5.6027C21.2108 6.41536 21.6673 7.51756 21.6673 8.66683V17.3335C21.6673 18.4828 21.2108 19.585 20.3981 20.3976C19.5855 21.2103 18.4833 21.6668 17.334 21.6668H8.66732C7.51805 21.6668 6.41585 21.2103 5.60319 20.3976C4.79053 19.585 4.33398 18.4828 4.33398 17.3335V8.66683Z" stroke="#1755F4" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
                           <path d="M9.75 13C9.75 13.862 10.0924 14.6886 10.7019 15.2981C11.3114 15.9076 12.138 16.25 13 16.25C13.862 16.25 14.6886 15.9076 15.2981 15.2981C15.9076 14.6886 16.25 13.862 16.25 13C16.25 12.138 15.9076 11.3114 15.2981 10.7019C14.6886 10.0924 13.862 9.75 13 9.75C12.138 9.75 11.3114 10.0924 10.7019 10.7019C10.0924 11.3114 9.75 12.138 9.75 13Z" stroke="#1755F4" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
                           <path d="M17.875 8.125V8.13542" stroke="#1755F4" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
                       </g>
                       <defs>
                           <clipPath id="clip0_293_34">
                               <rect width="26" height="26" fill="white" />
                           </clipPath>
                       </defs>
                   </svg>
                   </a>
                   {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <g clip-path="url(#clip0_293_39)">
                           <path d="M13 11.0218C14.9132 8.00583 16.5913 6.5 18.0353 6.5C20.202 6.5 21.5703 8.89742 22.3687 12.1518C23.1313 15.2598 22.9103 19.5 20.202 19.5C18.9952 19.5 17.3333 17.8046 15.7083 15.5437C14.6743 14.119 13.768 12.6058 13 11.0218Z" stroke="#1755F4" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
                           <path d="M12.9996 11.0218C11.0864 8.00583 9.40835 6.5 7.96426 6.5C5.7976 6.5 4.42935 8.89742 3.63093 12.1518C2.86826 15.2598 3.08926 19.5 5.7976 19.5C7.00443 19.5 8.66626 17.8046 10.2913 15.5437C11.3746 14.0367 12.277 12.5288 12.9996 11.0218Z" stroke="#1755F4" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
                       </g>
                       <defs>
                           <clipPath id="clip0_293_39">
                               <rect width="26" height="26" fill="white" />
                           </clipPath>
                       </defs>
                   </svg> */}
                   <a href='https://www.linkedin.com/company/kapxr/' target="_blank">
                   <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <g clip-path="url(#clip0_293_43)">
                           <path d="M4.5 6.50016C4.5 5.92553 4.73705 5.37443 5.15901 4.9681C5.58097 4.56177 6.15326 4.3335 6.75 4.3335H20.25C20.8467 4.3335 21.419 4.56177 21.841 4.9681C22.2629 5.37443 22.5 5.92553 22.5 6.50016V19.5002C22.5 20.0748 22.2629 20.6259 21.841 21.0322C21.419 21.4386 20.8467 21.6668 20.25 21.6668H6.75C6.15326 21.6668 5.58097 21.4386 5.15901 21.0322C4.73705 20.6259 4.5 20.0748 4.5 19.5002V6.50016Z" stroke="#1755F4" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
                           <path d="M9 11.9165V17.3332" stroke="#1755F4" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
                           <path d="M9 8.6665V8.67692" stroke="#1755F4" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
                           <path d="M13.5 17.3332V11.9165" stroke="#1755F4" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
                           <path d="M18 17.3332V14.0832C18 13.5085 17.7629 12.9574 17.341 12.5511C16.919 12.1448 16.3467 11.9165 15.75 11.9165C15.1533 11.9165 14.581 12.1448 14.159 12.5511C13.7371 12.9574 13.5 13.5085 13.5 14.0832" stroke="#1755F4" stroke-width="0.666667" stroke-linecap="round" stroke-linejoin="round" />
                       </g>
                       <defs>
                           <clipPath id="clip0_293_43">
                               <rect width="27" height="26" fill="white" />
                           </clipPath>
                       </defs>
                   </svg>
                   </a>
                   {/* <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                       <g clip-path="url(#clip0_293_50)">
                           <path d="M15.2304 3.69429C13.2728 4.52521 11.9858 6.35171 11.9197 8.43929L11.9176 8.63646L11.6543 8.61154C9.06298 8.32012 6.78148 6.97354 5.21498 4.78629C5.10891 4.63805 4.96715 4.51898 4.80282 4.44009C4.6385 4.3612 4.45692 4.32506 4.27491 4.33499C4.09291 4.34493 3.91635 4.40063 3.76159 4.49694C3.60683 4.59325 3.47887 4.72705 3.38957 4.88596L3.28448 5.08746L3.2314 5.19471C2.45248 6.80346 1.94223 8.75887 2.12965 10.8313L2.16215 11.127C2.46873 13.5786 3.78715 15.6933 6.25607 17.2793L6.44348 17.3952L6.35573 17.4418C4.93115 18.16 3.6279 18.4731 2.20982 18.4168C1.06582 18.3735 0.643317 19.9031 1.64865 20.4513C5.54648 22.5757 9.7314 23.2311 13.34 22.1846C17.7383 20.9063 21.088 17.6097 22.3696 13.0489L22.5072 12.5126C22.765 11.4369 22.9102 10.3395 22.9416 9.23662L22.9448 8.87696L23.3706 8.03304L23.8472 7.09921L24.0791 6.62904L24.2069 6.36146C24.494 5.74937 24.7009 5.24237 24.8287 4.81229L24.8439 4.75162L24.8526 4.73212C25.0909 4.08971 24.6727 3.26096 23.8332 3.26096L23.701 3.26854C23.6154 3.27906 23.5314 3.29979 23.4507 3.33029L23.3576 3.37146C23.0484 3.5216 22.73 3.65186 22.4042 3.76146L22.0186 3.88604L21.725 3.97271L20.8887 4.20454C19.4413 2.99337 17.4827 2.84604 15.459 3.60437L15.2304 3.69429Z" fill="#1755F4" />
                       </g>
                       <defs>
                           <clipPath id="clip0_293_50">
                               <rect width="26" height="26" fill="white" />
                           </clipPath>
                       </defs>
                   </svg> */}
               </div> 
                
               }
                <div className={styles.footerLogo} style={{display:'flex',flexDirection:'row',alignItems:'center',marginBottom:'10px'}}  >
                <div className={styles.verticalLine1}></div>
                    <div className={styles.logo}>
                        {/* <span>KAPXR</span> */}
                        <svg width="120" height="25" viewBox="0 0 243 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.208 0.719999V26.208L31.432 0.719999H45.184L27.76 23.472L45.328 54H31.864L20.128 33.408L12.208 43.776V54H0.544001V0.719999H12.208ZM58.8629 54H46.6949L65.7029 0.719999H77.0789L96.0149 54H83.6309L79.8869 42.768H62.6789L58.8629 54ZM69.8789 21.6L66.0629 32.976H76.5749L72.7589 21.6C72.1829 19.8 71.5349 17.712 71.3189 16.416C71.1029 17.64 70.5269 19.656 69.8789 21.6ZM123.816 36.288H113.88V54H102.216V0.719999H123.816C134.688 0.719999 141.96 7.848 141.96 18.504C141.96 29.16 134.688 36.288 123.816 36.288ZM121.224 11.088H113.88V25.92H121.224C126.84 25.92 129.648 23.472 129.648 18.504C129.648 13.536 126.84 11.088 121.224 11.088ZM162.861 27.36L145.725 0.719999H159.621L164.085 7.704C166.029 10.728 167.901 13.968 170.277 18.36L180.933 0.719999H194.541L177.693 27.36L194.541 54H180.717L177.189 48.456C174.525 44.208 172.797 41.04 170.205 36.288L158.757 54H145.077L162.861 27.36ZM213.021 54H201.357V0.719999H221.949C234.909 0.719999 242.253 6.984 242.253 18C242.253 24.912 239.301 29.808 233.397 32.832L242.757 54H230.013L222.021 35.352H213.021V54ZM213.021 11.088V25.128H221.877C226.989 25.128 229.941 22.536 229.941 18C229.941 13.536 227.133 11.088 221.949 11.088H213.021Z" fill="#1755F4"/>
                        </svg>
                    </div>
                   
                    {/* <p>Growing the Happiness</p> */}
                    
                </div>
            </div>
        </footer>
    );
};

export default SiteFooter;
