import React from 'react'
import Navbar from '../Components/Navbar/Navbar'
import MainFooter from '../Components/Footer/MainFooter'
import ContactUsIntro from '../Components/ContactUs/ContactUsIntro/ContactUsIntro'
import ContactUsImage from '../Components/ContactUs/ContactUsImage/ContactUsImage'
import ContactForm from '../Components/ContactUs/ContactForm/ContactForm'

function ContactUs() {
    return (
        <>
            <Navbar />
            <ContactUsIntro />
            <ContactUsImage />
            <ContactForm/>
            <MainFooter />
        </>
    )
}

export default ContactUs