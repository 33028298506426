import React from "react";
import styles from '../ContactUsButton/ContactUsButton.module.css';


const ContactUsButton = () => {
    return (
       <a target="_blank" href="https://calendly.com/contactkapxr/30min">
       <button className={styles.contactUsButton}>
        Contact us
        </button>
        </a>
    );
};

export default ContactUsButton;

