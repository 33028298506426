import React from 'react';
import styles from './OurPeople.module.css';
import image1 from '../../../Assets/Images/AboutUs/AbousUs_Card1.png'; // Import the image for card 2
import image2 from '../../../Assets/Images/AboutUs/AboutUs_Card2.png';
import image3 from '../../../Assets/Images/AboutUs/AboutUs_Card3.png';
const OurPeople = () => {
    return (
        <div className={styles.container}>
            <h2 className={styles.smallHeading}>OUR PEOPLE</h2>
            <p className={styles.description}>We hear over and over again that it feels different to work with us</p>
            <button className={styles.moreMembersButton}>More Members</button>
            <div className={styles.cardContainer}>
                <div className={styles.card}>
                    <h3 className={styles.cardHeading}>Build your career with KAPXR ?</h3>
                    <p className={styles.cardDescription}>Discover opportunities to grow and develop your career with us.</p>
                    <button className={styles.applyNowButton}>Apply Now</button>
                </div>
                <div className={styles.card}>
                    <img src={image1} alt="Card 2" className={styles.cardImage} />
                    <div className={styles.caption}>
                        <p style={{fontWeight:'550'}}>Waron Buffer</p>
                        <p>Founder, CEO</p>
                    </div>
                </div>
                <div className={styles.card}>
                    <img src={image2} alt="Card 2" className={styles.cardImage} />
                    <div className={styles.caption}>
                        <p style={{fontWeight:'550'}}>Waron Buffer</p>
                        <p>Founder, CEO</p>
                    </div>
                </div>
                <div className={styles.card}>
                    <img src={image3} alt="Card 2" className={styles.cardImage} />
                    <div className={styles.caption}>
                        <p style={{fontWeight:'550'}}>Waron Buffer</p>
                        <p>Founder, CEO</p>
                    </div>
                </div>
               
            </div>
        </div>
    );
};

export default OurPeople;
