

import React from 'react';
import styles from './BlogPeople.module.css';
import blog_people_1 from '../../../Assets/Images/BlogPage/blog_people_1.png'
import blog_people_2 from '../../../Assets/Images/BlogPage/blog_people_2.png'
import blog_people_3 from '../../../Assets/Images/BlogPage/blog_people_3.png'
const BlogPeople = () => {
    return (
        <div className={styles.container}>
            <h2 className={styles.heading}>
                “The live efficiency in the role of <span style={{color:'#1755F4'}}>Kapxr</span> on the particular word”
            </h2>
            <div className={styles.cardsContainer}>
                <div className={styles.card}>
                    <img src={blog_people_1} alt="Avatar" className={styles.avatar} />
                    <h3 className={styles.cardHeading}>Lary Thomas, Customer</h3>
                    <p className={styles.description}>
                        "I purchased the XYZ Smartwatch for its promise of convenience and connectivity, and it hasn’t disappointed. Syncing it with my smartphone was seamless, and now I can easily receive notifications.”
                    </p>
                </div>
                <div className={styles.card}>
                    <img src={blog_people_2} alt="Avatar" className={styles.avatar} />
                    <h3 className={styles.cardHeading}>Lary Thomas, Customer</h3>
                    <p className={styles.description}>
                        "I purchased the XYZ Smartwatch for its promise of convenience and connectivity, and it hasn’t disappointed. Syncing it with my smartphone was seamless, and now I can easily receive notifications.”
                    </p>
                </div>
                <div className={styles.card}>
                    <img src={blog_people_3} alt="Avatar" className={styles.avatar} />
                    <h3 className={styles.cardHeading}>Lary Thomas, Customer</h3>
                    <p className={styles.description}>
                        "I purchased the XYZ Smartwatch for its promise of convenience and connectivity, and it hasn’t disappointed. Syncing it with my smartphone was seamless, and now I can easily receive notifications.”
                    </p>
                </div>
                {/* Add more cards here */}
            </div>
        </div>
    );
};

export default BlogPeople;
