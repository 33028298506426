// ImageAboutus.js

import React from 'react';
import styles from './ImageAboutus.module.css';
import aboutUsImage from '../../../Assets/Images/AboutUs/AboutUs_HomePage.png';
import image1 from '../../../Assets/Images/AboutUs/AboutUs_HomePage_2.png'
const ImageAboutus = () => {
    return (
        <div className={styles.container}>
            <h3 className={styles.smallHeading}>About Us</h3>
            <hr className={styles.horizontalLine} />
            <h1 className={styles.bigHeading}>Explore who we are </h1>
            <img src={aboutUsImage} alt="About Us" className={styles.aboutUsImage} />
            <div className={styles.contentContainer}>
                <div className={styles.textContainer}>
                    <h2 className={styles.heading}>Building greater futures through innovation and collective knowledge</h2>
                    <p className={styles.description}>
                        Tata Consultancy Services is an IT services, consulting and business solutions organization 
                        that has been partnering with many of the world’s largest businesses for the past 50 years. 
                        We believe innovation and collective knowledge can transform all our futures with greater purpose.
                    </p>
                </div>
                <img src={image1} alt="TCS Logo" className={styles.tcsLogo} />
            </div>
            <hr className={styles.horizontalLine1} /> 
        </div>
        
    );
};

export default ImageAboutus;
