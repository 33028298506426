import React from 'react';
import Navbar from '../Components/Navbar/Navbar';
import MainFooter from '../Components/Footer/MainFooter'; 
import IndividualBlogPage from '../Components/BlogPageComponents/IndividualBlogPage/IndividualBlogPage';
import BlogAchievement from '../Components/BlogPageComponents/BlogAchievement/BlogAchievement';
function IndividualBlog(props) {
    return (
        <div>
            <Navbar/>
            <IndividualBlogPage/>
            <BlogAchievement/>
            <MainFooter/>
        </div>
    );
}

export default IndividualBlog;